import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";

function App() {
  return (
    <div className="container text-center">
      <div style={{marginRight: "auto", marginLeft: "auto"}}>404 - not found</div>
    </div>


    // <div className="background">
    //   <div className="container row">
    //     <div className="align-center col-lg-6 mt-5">
    //       <h1>
    //         Fookus Film <i className="bi bi-camera-reels"></i>
    //       </h1>
    //       <div className="mt-5 align-center description">
    //         Fookus Film on reklaamide, filmide ja telesaadete produktsioon.
    //         Fookuse portfooliosse kuuluvad sellised teosed nagu “Erik Kivisüda”,
    //         Cannes'i filmifestivalil grand prix' võitnud “Kupee nr 6”,
    //         “Teesklejad”, “Seneca päev”, “Kirsitubakas” ja teleseriaal
    //         “Nöbinina”.
    //       </div>
    //     </div>
    //     <div className="align-center col-lg-6 mt-5">
    //       <img src={avatar} className="avatar" alt="Avatar"/>
    //       <h3 className="mt-4">Mari Tamm</h3>
    //       <h4 className="mt-1">Produtsent</h4>
    //       <h5 className="mt-3">mari@tamm.ee</h5>
    //       <h5 className="mt-1">+372 5555 5555</h5>
    //     </div>
    //   </div>
    //   <footer className="pt-3 mt-5">
    //     <div className="border-bottom mt-3"></div>
    //     <p className="mt-3">Fookus Film OÜ<br></br>
    //     Värsi 23, 10919 Tallinn<br></br>
    //     Registrikood 11398431</p>
    //   </footer>
    // </div>
  );
}

export default App;
